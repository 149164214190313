<template>
  <div class="home">
    <Nav></Nav>
    <section class="home-banner">
      <div class="home-banner-center justify-between ">
        <div class="banber-left">
          <div class="banber-logo">
            <img src="@/assets/icon/home_banner_icon.png" alt="">
          </div>
          <h4 class="banner-title">女孩们的专属找搭子APP</h4>
          <h6 class="banner-text">轻松找到志同道合的姐妹搭子，一起嗨翻天！</h6>
          <div class="btn-box">
            <div class="btn-item iosBtn" @mouseover="generateQRCode(appleDownloadUrl)">
              <div class="QrcodeBox">
                <img :src="qrCodeDataUrl" alt="QR Code">
              </div>
            </div>
            <div class="btn-item androidBtn" @mouseover="generateQRCode(androidDownloadUrl)">
              <div class="QrcodeBox">
                <img :src="qrCodeDataUrl" alt="QR Code">
              </div>
            </div>
          </div>
        </div>
        <div class="banner-img">
          <img src="@/assets/img/banner_img.png" alt="">
        </div>
      </div>
    </section>
    <section class="tag-box">
      <section class="tag-content">
        <div class="tag-title-icon">
          <img src="@/assets/icon/tag_top_icon.png" alt="">
        </div>
        <h4 class="tag-title">女孩们的秘密基地</h4>
        <h6 class="tag-text">安全私密，轻松找到兴趣相投的姐妹搭子！</h6>
        <div class="tag-ul" id="tag-ul">
          <li class="tag-ul-li" ref="boxDescribe">City Walk</li>
          <li class="tag-ul-li">剧本杀/密室/桌游</li>
          <li class="tag-ul-li">live演出/演唱会/音乐节</li>
          <li class="tag-ul-li">旅行/摄影</li>
          <li class="tag-ul-li">美食/探店</li>
          <li class="tag-ul-li">运动/健身</li>
          <li class="tag-ul-li">美甲/美睫/美发</li>
          <li class="tag-ul-li">户外/爬山</li>
          <li class="tag-ul-li">电影/话剧/展览</li>
          <li class="tag-middle-logo pc-x">
            <img src="@/assets/icon/tag_middle_icon.png" alt="">
          </li>
        </div>
        <li class="breatheA"></li>
        <li class="breatheB"></li>
        <li class="breatheC"></li>
        <li class="breatheD"></li>
      </section>
    </section>
    <section class="merit-box">
      <div class="merit-content">
        <li class="merit-item" @mouseover="meritAnimation(1)">
          <div class="merit-close">
            <div class="merit-close-img">
              <img src="@/assets/icon/merit_icon1.png" alt="">
            </div>
            <h4 class="merit-close-tiele">找理想的搭子</h4>
          </div>
          <div class="merit-spread">
            <div class="merit-spread-img">
              <img src="@/assets/icon/merit_icon1.png" alt="">
            </div>
            <h4 class="merit-spread-tiele">找理想的搭子</h4>
            <h6 class="merit-spread-text">基于用户的兴趣爱好进行智能匹配，推荐最可能成为好友的搭子，无论是城市探险、美食分享还是健身活动，您都能找到合适的伙伴。</h6>
          </div>
        </li>
        <li class="merit-item" @mouseover="meritAnimation(2)">
          <div class="merit-close" style="background: rgba(180, 166, 249, 0.08);">
            <div class="merit-close-img">
              <img src="@/assets/icon/merit_icon2.png" alt="">
            </div>
            <h4 class="merit-close-tiele">多元化的聊天</h4>
          </div>
          <div class="merit-spread merit-spread2">
            <div class="merit-spread-img">
              <img src="@/assets/icon/merit_icon2.png" alt="">
            </div>
            <h4 class="merit-spread-tiele">多元化的聊天</h4>
            <h6 class="merit-spread-text">不仅支持一对一的私密对话，还允许创建群聊，与多位朋友共同讨论、分享，使社交体验更加丰富多彩。</h6>
          </div>
        </li>
        <li class="merit-item" @mouseover="meritAnimation(3)">
          <div class="merit-close" style="background:rgba(86, 152, 248, 0.05)">
            <div class="merit-close-img">
              <img src="@/assets/icon/merit_icon3.png" alt="">
            </div>
            <h4 class="merit-close-tiele">安全与隐私</h4>
          </div>
          <div class="merit-spread merit-spread3">
            <div class="merit-spread-img">
              <img src="@/assets/icon/merit_icon3.png" alt="">
            </div>
            <h4 class="merit-spread-tiele">安全与隐私</h4>
            <h6 class="merit-spread-text">我们高度重视用户的隐私和数据安全。所有的聊天数据都经过加密，确保只有聊天双方可以访问。</h6>
          </div>
        </li>
        <li class="merit-item" @mouseover="meritAnimation(4)">
          <div class="merit-close" style="background:rgba(250, 136, 125, 0.10)">
            <div class="merit-close-img">
              <img src="@/assets/icon/merit_icon4.png" alt="">
            </div>
            <h4 class="merit-close-tiele">热门话题</h4>
          </div>
          <div class="merit-spread merit-spread4">
            <div class="merit-spread-img">
              <img src="@/assets/icon/merit_icon4.png" alt="">
            </div>
            <h4 class="merit-spread-tiele">热门话题</h4>
            <h6 class="merit-spread-text">APP内定期推出不同的话题讨论，鼓励用户参与，增加交流的深度和广度，让您在享受社交乐趣的同时，也能获得知识和信息。</h6>
          </div>
        </li>
      </div>
    </section>
    <section class="dazi-box align-center  justify-between ">
      <div class="dazi-content">
        <h4 class="title">
          快加入我们，开启你的姐妹淘之旅！
        </h4>
        <ul class="img-list">
          <li class="img-item li-a">
            <img src="../../assets/img/dazi_img3.png" alt="">
          </li>
          <li class="img-item li-b">
            <img src="../../assets/img/dazi_img2.png" alt="">
          </li>
          <li class="img-item li-c" style="z-index: 3;">
            <img src="../../assets/img/dazi_img1.png" alt="">
          </li>
          <li class="img-item li-d">
            <img src="../../assets/img/dazi_img4.png" alt="">
          </li>
          <li class="img-item li-e">
            <img src="../../assets/img/dazi_img5.png" alt="">
          </li>
        </ul>
      </div>
    </section>
    <goTop />
    <Footer @downloadUrl="getDownloadUrl"></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import goTop from './components/goTop.vue'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import QRCode from 'qrcode'
export default {
  name: 'Home',
  data() {
    return {
      appleDownloadUrl: '',
      androidDownloadUrl: '',
      qrShow: null,
      qrCodeDataUrl: null,
    }
  },
  mounted() {
    this.initGsap();
  },
  components: {
    Nav,
    Footer,
    goTop
  },
  computed: {
  },
  methods: {
    // 初始化动画
    initGsap() {
      gsap.registerPlugin(ScrollTrigger);
      this.launchAnimation();
      this.meritAnimation(1)
    },
    launchAnimation() {
      gsap.utils.toArray('.tag-ul').forEach((section, index) => {
        const liA = section.querySelectorAll('.tag-ul-li')
        gsap.set(liA, { display: 'none' });
        liA.forEach((li, index) => {
          let x = null;
          let y = null;
          if (index == 0) {
            x = '-555rem';
            y = '-236rem';
          } else if (index == 1) {
            x = '-674rem';
            y = '36rem';
          } else if (index == 2) {
            x = '-470rem';
            y = '235rem';
          } else if (index == 3) {
            x = '-355rem';
            y = '-55rem';
          } else if (index == 4) {
            x = '-135rem';
            y = '-288rem';
          }
          else if (index == 5) {
            x = '275rem';
            y = '52rem';
          }
          else if (index == 6) {
            x = '355rem';
            y = '260rem';
          } else if (index == 7) {
            x = '390rem';
            y = '-210rem';
          } else if (index == 8) {
            x = '500rem';
          }
          gsap.to(li, {
            x: x,
            y: y,
            opacity: 1,
            display: 'block',
            duration: 1,
            scrollTrigger: {
              trigger: '.tag-box',
              start: 'top bottom',
              end: "bottom center",
              scrub: true
            }
          })

        })
      })
      gsap.utils.toArray('.img-list').forEach((section, index) => {
        const imgList = section.querySelectorAll('.img-item')
        imgList.forEach((li, index) => {
          let x = null;
          if (index == 0) {
            x = '724rem'
          }
          if (index == 1) {
            x = '375rem';
          }
          if (index == 3) {
            x = '-724rem'
          }
          if (index == 4) {
            x = '-375rem'
          }
          if (index != 2) {
            gsap.to(li, {
              x: x,
              duration: 1,
              scrollTrigger: {
                trigger: '.img-list',
                start: 'top bottom',
                end: "bottom bottom",
                scrub: true
              }
            })
          }
        })
      })
    },
    meritAnimation(i) {
      gsap.utils.toArray('.merit-content').forEach((section, index) => {
        const list = section.querySelectorAll('.merit-item')
        list.forEach((li, index) => {
          if (i == (index + 1)) {
            const spread = li.querySelector('.merit-spread')
            const close = li.querySelector('.merit-close')
            gsap.to(close, { ease: "power2.inout", opacity: 0, duration: .6, })
            gsap.to(spread, { opacity: 1, ease: "power2.inout", duration: .6, })
            gsap.to(li, {
              width: '581rem ', duration: 0.6, ease: 'power2.inout', // 添加缓动效果，可以根据需要选择不同的缓动函数
            })
          } else {
            const spread = li.querySelector('.merit-spread')
            const close = li.querySelector('.merit-close')
            gsap.to(spread, { opacity: 0, ease: "power2.inout", duration: .6, })
            gsap.to(close, { opacity: 1, ease: "power2.inout", duration: .6, })
            gsap.to(li, { width: '200rem ', duration: 0.6, ease: 'power2.inout', })
          }
        })
      })
    },
    generateQRCode(url, ref) {
      try {
        const dataUrl = QRCode.toDataURL(url, { margin: 0 });
        dataUrl.then((a) => {
          this.qrCodeDataUrl = a;
        })

      } catch (error) {
        console.error("Error generating QR Code:", error);
      }
    },
    getDownloadUrl(data) {
      if (data.kind == 1) {
        this.appleDownloadUrl = data.url
      } else {
        this.androidDownloadUrl = data.url
      }
    }
  },

}
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  overflow: hidden;
  color: #333;
  font-size: 18rem;

  .home-banner {
    padding: 213rem 0 187rem 0;
    background:
      radial-gradient(95.18% 95.18% at 4.82% 2.16%, #FAF8EE 0%, rgba(255, 255, 255, 0.05) 100%),
      radial-gradient(98.41% 95.65% at 98.41% 0.93%, #E9FFF0 0%, rgba(255, 255, 255, 0.05) 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 75.6%, #FFFFFF 100%);

    .home-banner-center {
      width: 1280rem;
      margin: 0 auto;

      .banber-left {
        padding-top: 87rem,
      }

      .banber-logo {
        width: 307rem;
        height: 90rem;
        margin-bottom: 88rem;
      }

      .banner-title {
        height: 50rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        font-size: 35rem;
        color: #333333;
        line-height: 42rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 12rem;
      }

      .banner-text {
        height: 22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        font-size: 16rem;
        color: #666666;
        line-height: 19rem;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 43rem;
      }

      .btn-box {
        display: flex;

        .btn-item {
          width: 160rem;
          height: 48rem;
          border-radius: 12rem;
          margin-right: 36rem;
          cursor: pointer;
          position: relative;
        }

        .QrcodeBox {
          position: absolute;
          display: none;
          background: #ffffff;
          top: 58rem;
          width: 160rem;
          height: 160rem;
          box-shadow: 0rem 0rem 30rem 0rem rgba(0, 128, 128, 0.1);
          border-radius: 16rem;
          padding: 15rem;
          opacity: 0;

          .Qrcode {
            width: 130rem;
            height: 130rem;
          }
        }



        .iosBtn {
          background: url('../../assets/icon/iosBtn.png');
          background-size: 100% 100%;
        }

        .iosBtn:hover {
          background: url('../../assets/icon/iosBtn_high.png');
          background-size: 100% 100%;

          .QrcodeBox {
            opacity: 1;
            display: block;
            transition: all 3s ease-in-out;
          }
        }

        .androidBtn {
          background: url('../../assets/icon/androidBtn.png');
          background-size: 100% 100%;
        }

        .androidBtn:hover {
          background: url('../../assets/icon/androidBtn_high.png');
          background-size: 100% 100%;

          .QrcodeBox {
            opacity: 1;
            display: block;
            transition: all 3s ease-in-out;
          }
        }

        .btn-item:last-child {
          margin-right: 0;
        }
      }

      .banner-img {
        width: 735rem;
        height: 621rem;
      }
    }


  }

  .tag-box {
    width: 100%;
    background: url("../../assets/img/tag_background.png");
    background-size: cover;
    padding-top: 118rem;
    height: 1100rem;

    .tag-content {
      width: 1280rem;
      margin: 0 auto;
      position: relative;

      .tag-title-icon {
        width: 70rem;
        height: 72rem;
        margin: 0 auto;
        margin-bottom: 36rem;
      }

      .tag-title {
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        font-size: 40rem;
        color: #333333;
        line-height: 47rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-bottom: 24rem;

        span {
          background: -webkit-linear-gradient(180deg, rgba(235, 255, 0, 1) 0%, #00F284 100%);

          /* Chrome, Safari */
          background: linear-gradient(180deg, #EBFF00 0%, #00F284 100%);
          /* 标准语法 */
          -webkit-background-clip: text;
          /* Chrome, Safari */
          background-clip: text;
          -webkit-text-fill-color: transparent;
          /* Chrome, Safari */
          color: transparent;
          /* 兼容不支持background-clip的浏览器 */
        }
      }

      .tag-text {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        font-size: 18rem;
        color: #999999;
        line-height: 21rem;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }

      .tag-ul {
        width: 1180rem;
        height: 818rem;
        background: url("../../assets/img/tag_ul_bg.png");
        background-size: 100% 100%;
        position: relative;
        top: -77rem;

        .tag-middle-logo {
          width: 168rem;
          height: 168rem;
          top: 368rem;
        }

        .tag-ul-li {
          display: inline-block;
          height: 80rem;
          padding: 0 24rem;
          background: #FFFFFF;
          box-shadow: 12rem 34rem 50rem 0rem rgba(0, 128, 128, 0.05), -11rem -12rem 50rem 0rem rgba(0, 128, 128, 0.05);
          border-radius: 100%;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-size: 18rem;
          color: #666666;
          line-height: 80rem;
          text-align: center;
          font-style: normal;
          text-transform: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%);
        }
      }

      .breatheA {
        width: 13rem;
        height: 13rem;
        background: #0066D9;
        border-radius: 50%;
        position: absolute;
        top: 468rem;
        left: -30rem;
        animation: breathe 3s infinite alternate;
      }

      .breatheB {
        width: 21rem;
        height: 21rem;
        background: #FFAA45;
        border-radius: 50%;
        position: absolute;
        top: 655rem;
        left: 367rem;
        animation: breathe 4s infinite alternate;
      }

      .breatheC {
        width: 12rem;
        height: 12rem;
        background: #EA4335;
        border-radius: 50%;
        position: absolute;
        top: 369rem;
        left: 646rem;
        animation: breathe 3s infinite alternate;
      }

      .breatheD {
        width: 13rem;
        height: 13rem;
        background: #0066D9;
        border-radius: 50%;
        position: absolute;
        top: 678rem;
        left: 998rem;
        animation: breathe 2s infinite alternate;
      }

      @keyframes breathe {
        0% {
          transform: scale(.3);
          opacity: 0.5;
        }

        100% {
          transform: scale(1.2);
          opacity: 1;
        }
      }
    }
  }

  .merit-box {
    width: 100%;
    background: #ffffff;
    padding: 228rem 0 260rem 0;

    .merit-content {
      width: 1280rem;
      margin: 0 auto;
      display: flex;

      .merit-item {
        display: inline-block;
        margin-right: 33rem;
        height: 443rem;
        border-radius: 30rem;
        width: 200rem;
        cursor: default;
        overflow: hidden;
        position: relative;

        .merit-close {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: rgba(150, 235, 199, 0.10);
          border-radius: 30rem;

          .merit-close-img {
            width: 60rem;
            height: 60rem;
            margin: 0 auto;
            margin-bottom: 24rem;
          }

          .merit-close-tiele {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            font-size: 24rem;
            color: #333333;
            line-height: 28rem;
            text-align: center;
            font-style: normal;
            text-transform: none;
          }
        }

        .merit-spread {
          width: 581rem;
          position: absolute;
          // display: none;
          height: 100%;
          background: url("../../assets/img/merit_spread_bg.png");
          background-size: 100% 100%;
          border-radius: 30rem;
          padding: 40rem;

          .merit-spread-img {
            width: 60rem;
            height: 60rem;
            margin-bottom: 28rem;
          }

          .merit-spread-tiele {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            font-size: 28rem;
            color: #333333;
            line-height: 33rem;
            margin-bottom: 101rem;
          }

          .merit-spread-text {
            width: 304rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-size: 18rem;
            color: #666666;
            line-height: 29rem;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }

        .merit-spread2 {
          background: url("../../assets/img/img2.png");
          background-size: 100% 100%;
        }

        .merit-spread3 {
          background: url("../../assets/img/img3.png");
          background-size: 100% 100%;
        }

        .merit-spread4 {
          background: url("../../assets/img/img4.png");
          background-size: 100% 100%;
        }
      }

      .merit-item:last-child {
        margin-right: 0;
      }
    }
  }

  .dazi-box {
    padding: 103rem 0;
    height: 1100rem;
    display: flex;
    width: 100%;
    background: url("../../assets/img/dazi_bg.png");
    background-size: cover;

    .dazi-content {
      width: 1860rem;
      margin: 0 auto;
    }

    .title {
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      font-size: 44rem;
      color: #333333;
      line-height: 52rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 88rem;
    }

    .img-list {
      position: relative;
      height: 743rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .img-item {
        border-radius: 42rem;

        img {
          border-radius: 42rem;
        }
      }

      .li-c {
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 343rem;
        height: 743rem;
      }

      .li-a,
      .li-d {
        width: 309rem;
        height: 669rem;
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      .li-b,
      .li-e {
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 329rem;
        height: 713rem
      }
    }
  }
}
</style>